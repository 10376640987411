<template>
  <div class="container">

    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <!-- <div class="dohand">
        <div class="preset">预设</div>
        <div class="amount">金额<input type="text" /></div>
        <div class="btn none">确认</div>
        <div class="btn yes">重置</div>
        <div class="btn2 a">结果走势</div>
     
      </div> -->
      <div class="player listmain type-s3s spk3 game-spk3 game-s3s-hs">
        <!-- 和值 -->
        <div class="data" v-for="item in rowsData" :key="item.title">
          <h3>{{item.title}}</h3>
          <ul>

            <li v-for="ele in item.arr" :key="ele.ResultID" :class="{selected:ele.selected,w20:item.title=='跨度'}" @click="setCurrentSelected(ele)">
              <ol class="td_name">{{ele.label}}</ol>
              <ol class="td_rate">{{ele.Odds}}</ol>
              <ol class="td_cash">

                <input type="numner" @click.stop="showOrHideCheck($event, ele)" v-model="ele.money" />
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li style="width:40px" v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>

              </ol>
            </li>
            <li v-if="item.title=='豹、顺、对、杂、红、黑'"></li>
            <!-- <li v-if="item.title=='豹、顺、对、杂、红、黑'"></li> -->

          </ul>
        </div>

      </div>
      <yushe />
      <!-- b表格 -->
      <div class="roadmap">
        <table class="ltbinfo">
          <tbody>
            <tr>
              <th v-for="item in ltTitleArr" @click="changeQiu(item.index)" :class="item.index==qiuIndex?'selected':''" :key="item.title">{{item.title}}</th>
            </tr>
          </tbody>
        </table>

        <!-- b表格1111 -->
        <table colspan="0" id="lt_sum_ds" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr v-for="(item,index) in ltArr" :key="index">
              <td v-for="(ele,i) in item" :key="ele+i">
                <span :class="fontColor[ele]">{{ele}}</span>

              </td>

            </tr>
          </tbody>
        </table>
        <!--  -->

      </div>
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import yanzheng from "../yanzheng.vue";
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      groupnames:'hzlm,lzlm',
      ltArr: [],
      ltTitleArr: [
        { title: "第一球", index: 1 },
        { title: "第二球", index: 2 },
        { title: "第三球", index: 3 },
        { title: "总和单双", index: 4 },
        { title: "总和大小", index: 5 },
      ],
      tableType: 1,
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [
        { title: "和值", arr: [] },
        { title: "豹、顺、对、杂、红、黑", arr: [] },
        { title: "和值双面", arr: [] },
        { title: "和值过关", arr: [] },
        { title: "跨度", arr: [] },
      ],
      labelArr1: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
      // 豹子后面少个对子
      labelArr2: ["红码", "黑码", "豹子", "对子", "顺子", "半顺", "杂六"],
      labelArr3: ["总和大", "总和小", "总和单", "总和双"],
      labelArr4: [
        "大单",
        "大双",
        "小单",
        "小双",
        "红大",
        "红小",
        "红单",
        "红双",
        "黑大",
        "黑小",
        "黑单",
        "黑双",
      ],
      labelArr5: [1, 2, 3, 4, 5],
      lutu1: {
        arr1: [[], [], [], [], []],
      },

      lutu2: {
        arr1: [[], [], [], [], []], //单双
      },

      lutu3: {
        arr1: [[], [], [], [], []], //单
      },

      lutu4: {
        arr1: [[], [], [], [], []],
      },

      lutu5: {
        arr1: [[], [], [], [], []],
      },
    };
  },
  components: {
    yushe,
    topTimer,
    yanzheng,
  },
  watch: {
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title:
                    item.title == "豹、顺、对、杂、红、黑" ? "" : item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        // 和值
        let arr0 = this.ddFilterData1(resAArr, [3878], ["3"]);
        let arr1 = this.filterData(resAArr, 920, 933, this.labelArr1);
        let arr2 = this.ddFilterData1(resAArr, [3879], ["18"]);
        this.rowsData[0].arr = arr0.concat(arr1, arr2);
        //  豹、顺、对、杂、红、黑
        let arr3 = this.ddFilterData1(
          resAArr,
          [3039, 3040, 869, 3966, 3036, 3037, 3038],
          this.labelArr2
        );
        this.rowsData[1].arr = arr3;
        // 和值双面
        let arr4 = this.filterData(resAArr, 859, 862, this.labelArr3);
        this.rowsData[2].arr = arr4;
        // 和值过关
        let arr5 = this.ddFilterData1(
          resAArr,
          [
            3032, 3033, 3034, 3035, 3955, 3956, 3957, 3958, 3959, 3960, 3961,
            3962,
          ],
          this.labelArr4
        );
        this.rowsData[3].arr = arr5;

        // 跨度
        let arr6 = this.ddFilterData1(
          resAArr,
          [3044, 3963, 3964, 3965, 3045],
          this.labelArr5
        );
        this.rowsData[4].arr = arr6;
        this.$forceUpdate();
      },
    },
  },
  created() {},

  methods: {
    getKJHis() {
      this.lutu1 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu2 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu3 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu4 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu5 = {
        arr1: [[], [], [], [], []],
      };

      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        pagenum: 1,
        pagecount: 200,
        roomeng: this.currentGame.roomeng,
        begindate: this.splitDate(this.getBeforeDate(0)),
        enddate: this.splitDate(this.getBeforeDate(0)),
      };
      this.$http.post("getlotteryhis", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          if (resObj.data) {
            let arr1 = [[]],
              arr2 = [[]],
              arr3 = [[]],
              arr4 = [[]],
              arr5 = [[]];

            // --------------
            let list = resObj.data;
            list.reverse()
            list.forEach((element, index) => {
              element.codeArr = element.Opencode.split(",");

              let q1 = element.codeArr[0];
              let q2 = element.codeArr[1];
              let q3 = element.codeArr[2];
              let sum = 0;
              element.codeArr.forEach((num) => {
                sum += Number(num);
              });
              // 第一球 单双-----------------------
              let ds1 = q1 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr1, ds1);
              // 第二球 单双
              let ds2 = q2 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr2, ds2);
              // 第三球 单双
              let ds3 = q3 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr3, ds3);
              // 总和单双
              let zhds = sum % 2 == 0 ? "双" : "单";
              this.initlutuData(arr4, zhds);
              // 总和大小
              let zhdx = sum >= 11 ? "大" : "小";
              this.initlutuData(arr5, zhdx);
              // ----------------------------------------------------------
            });
            //龙虎
            this.buquanArr(arr1, this.lutu1.arr1);
            this.buquanArr(arr2, this.lutu2.arr1);
            this.buquanArr(arr3, this.lutu3.arr1);
            this.buquanArr(arr4, this.lutu4.arr1);
            this.buquanArr(arr5, this.lutu5.arr1);

            this.ltArr = this["lutu" + this.qiuIndex]["arr" + this.ltIndex];
            this.$forceUpdate();
          } else {
            this.ltArr = [];
          }
        } else {
          this.ltArr = [];
        }
      });
    },
    // 切换 不一个类型得 表格
    setTableType(num) {
      this.tableType = num;
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style  scoped>
.listmain .data ul li.w20 {
  width: 20%;
}
.listmain .data ul li {
  width: 25%;
}
</style>